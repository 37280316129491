export default class NavbarComponent {
    constructor() {
        NavbarComponent.sticky()
        NavbarComponent.burger()
        NavbarComponent.langsSwitcher()
        NavbarComponent.ecoBarHeader()
    }

    static sticky() {
        let lastScrollTop = 0

        $(window).on('load scroll', function () {
            const offset = 100 //Trigger offset (~header height)
            const navbar = $('[data-id="wpiris-navbar"]')
            const scrollTop = $(this).scrollTop()
            let full = false

            //Hidden comportment
            if (scrollTop >= lastScrollTop && scrollTop > navbar.outerHeight()) {
                $('body').addClass('header-is-hidden')
            } else {
                $('body').removeClass('header-is-hidden')
            }

            // Detect if header is on banner/video mode
            if ($('body.has-banner').length > 0) {
                full = true
            }

            lastScrollTop = scrollTop

            if (scrollTop > offset) {
                navbar.removeClass('navbar--ontop')
                navbar.addClass('navbar--onscroll')
                if (full === true) {
                    NavbarComponent.changeLogoColor('classic')
                }
            } else {
                navbar.removeClass('navbar--onscroll')
                navbar.addClass('navbar--ontop')
                if (full === true) {
                    NavbarComponent.changeLogoColor('banner')
                }
            }
        })
    }

    static changeLogoColor(color) {
        const logo = $('[data-id="wpiris-logo"]')
        let logoBanner
        let logoClassic

        logoBanner = IRISCollectionCustomer.imageLogoBanner
        logoClassic = IRISCollectionCustomer.imageLogo

        if (color === 'banner') {
            logo.attr('src', logoBanner)
        } else if (color === 'classic') {
            logo.attr('src', logoClassic)
        }
    }

    static burger() {
        const body = $('body')
        const trigger = '[data-trigger="wpiris-menu-toggle"]'
        const more = '[data-trigger="wpiris-menu-more"]'
        const prev = '[data-trigger="wpiris-menu-prev"]'
        const submenu = '[data-trigger="wpiris-menu-submenu"]'

        //Open/Close menu
        $(trigger).on('click', () => {
            body.toggleClass('menu-open')

            // close ecobar
            if (body.hasClass('menu-open')) {
                $('body').removeClass('eco-bar-open')
                $('.eco-bar').addClass('eco-bar--hidden')
            }

            //Change logo on mobile
            if (window.matchMedia('(max-width: 650px)').matches) {
                if (body.hasClass('menu-open')) {
                    NavbarComponent.changeLogoColor('banner')
                } else {
                    if ($('.navbar--ontop').length > 0 && $('body.has-banner').length > 0) {
                        NavbarComponent.changeLogoColor('banner')
                    }
                }
            }
        })

        //Open/Close submenu
        $(more).on('click', function () {
            $(this).next(submenu).toggleClass('submenu-open')
        })

        $(prev).on('click', (e) => {
            e.preventDefault()
            $(submenu).removeClass('submenu-open')
        })
    }

    static ecoBarHeader() {
        $(document).ready(() => {
            if ($('.eco-bar').hasClass('eco-bar--hidden')) {
                $('body').removeClass('eco-bar-open')
            } else {
                if (!$('body').hasClass('error404') && !$('body').hasClass('search')) {
                    $('body').addClass('eco-bar-open')
                }
            }
        })

        $('.eco-bar__tab').on('click', () => {
            if ($('.eco-bar').hasClass('eco-bar--hidden')) {
                $('body').removeClass('eco-bar-open')
            } else {
                if (!$('body').hasClass('error404') && !$('body').hasClass('search')) {
                    $('body').addClass('eco-bar-open')
                }
            }
        })
    }

    static langsSwitcher() {
        const list_langs = $('.lang__list')

        // Fix current lang
        setTimeout(() => {
            const currentLang = $('html').attr('lang');
            let flagImgUrl;
            switch (currentLang) {
                case 'en':
                    flagImgUrl = IRISCollectionCustomer.assetsFlagUrlEn;
                    break;
                case 'de':
                    flagImgUrl = IRISCollectionCustomer.assetsFlagUrlDe;
                    break;
                case 'nl':
                    flagImgUrl = IRISCollectionCustomer.assetsFlagUrlNl;
                    break;
                case 'it':
                    flagImgUrl = IRISCollectionCustomer.assetsFlagUrlIt;
                    break;
                case 'es':
                    flagImgUrl = IRISCollectionCustomer.assetsFlagUrlEs;
                    break;
                default:
                    flagImgUrl = IRISCollectionCustomer.assetsFlagUrlFr;
                    break;
            }

            $('.lang__current').find('img').attr('src', flagImgUrl)
        }, '1000')

        // Open submenu
        $('.tools__item__languages').on('click', () => {
            $(list_langs).toggleClass('lang__list--open')
            $('.lang__list--open').fadeIn(250)
            $('.lang__list:not(.lang__list--open)').fadeOut(250)
        })

        // Change Navbar Language on click
        $('.lang__list > a').on('click', function () {
            $('.lang__list > a').show()
            $(this).hide()
            $(current_lang).text($(this).text())
        })
    }
}
